import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Form, Select, Input, Divider, Button } from 'antd';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { getDictionaryItemById } from '../../lib/utils/applications';
import { useAppSelector } from '../../store/hooks';
import SuggestInput from '../SuggestInput/SuggestInput';
import { PhonesTableDisplay } from '../PhonesTableDisplay/PhonesTableDisplay';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import selectFilterOption from '../../lib/utils/selectFilterOption';
import { PhonesTable } from '../PhonesTable/PhonesTable';
import helpIcon from '../../lib/img/icon/app_subject/help_map.svg';
import { useApplicationContainerContext } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { ReactComponent as PlusIcon } from '../../lib/img/plus.svg';
import { ReactComponent as DeleteIcon } from '../../lib/img/basket.svg';
import { uniqueId } from 'lodash';
import FireBaseService from '../../services/FireBaseService/FireBaseService';

interface IProps {
  editModeMain: boolean;
  editApplicationForm: any;
}

export const ApplicationMainAddress: FC<IProps> = ({ editModeMain, editApplicationForm }) => {
  const { hasAccess } = useMainContext();
  const { meIsChosenExecutor } = useApplicationContainerContext();
  const { application } = useAppSelector((state) => state.applications);
  const { subwayStation } = useAppSelector((state) => state.dictionaries);

  const [mapKey, setMapKey] = useState(uniqueId());

  const { importSVGIconStringForImg } = useMainContext();

  useEffect(() => {
    setMapKey(uniqueId());
  }, [application]);

  const nameAddress = { 0: 'Б', 1: 'В' };

  const renderMap = () => {
    const centerLatitude = application?.address?.coordinates?.point.latitude || 55.75;
    const centerLongitude = application?.address?.coordinates?.point.longitude || 37.57;
    return (
      <YMaps key={mapKey}>
        <Map
          defaultState={{
            center: [centerLatitude, centerLongitude],
            zoom: 13,
            controls: ['zoomControl', 'fullscreenControl'],
          }}
          modules={['control.ZoomControl', 'control.FullscreenControl']}
          width="98%"
        >
          <Placemark
            defaultGeometry={[centerLatitude, centerLongitude]}
            options={{
              iconLayout: 'default#image',
              iconImageHref: importSVGIconStringForImg(
                'app_subject',
                'help_map',
                'mapIconName',
                application?.subjectId
              ),
              iconImageOffset: [-15, 0],
              balloonMinHeight: 143,
              balloonMinWidth: 343,
            }}
          />
          {application?.additionalAddress?.map((address) => {
            const centerLatitude = address?.coordinates?.point.latitude || 55.75;
            const centerLongitude = address?.coordinates?.point.longitude || 37.57;
            return (
              <Placemark
                defaultGeometry={[centerLatitude, centerLongitude]}
                options={{
                  iconLayout: 'default#image',
                  iconImageHref: importSVGIconStringForImg(
                    'app_subject',
                    'help_map',
                    'mapIconName',
                    application?.subjectId
                  ),
                  iconImageOffset: [-15, 0],
                  balloonMinHeight: 143,
                  balloonMinWidth: 343,
                }}
              />
            );
          })}
        </Map>
      </YMaps>
    );
  };

  const renderContactData = () => {
    const subwayStationName = getDictionaryItemById(subwayStation, application?.address?.subwayStationId)?.name;
    const unstructuredAddress = application?.address?.unstructuredAddress || '';

    return (
      <>
        <h3 className="mb5 text-bold">
          <Row>Адрес А</Row>
        </h3>

        <Divider className="thin-divider mt0 mb10" />

        <Row gutter={20}>
          <Col span={16} className="display-flex">
            <div className="application-main__dataLabel">Адрес:</div>
            <div className="application-main__dataValue" title={unstructuredAddress}>
              {unstructuredAddress}
            </div>
          </Col>
          <Col span={8} className="display-flex">
            <div className="application-main__dataLabel">Метро:</div>
            <div className="application-main__dataValue">{subwayStationName}</div>
          </Col>
        </Row>
        {(meIsChosenExecutor || hasAccess(['APPLICATIONS.READ.FULLACCESS'])) && (
          <>
            <Row gutter={20}>
              <Col span={16} className="display-flex">
                <div className="application-main__dataLabel">Квартира:</div>
                <div className="application-main__dataValue">{application?.address?.apartment}</div>
              </Col>
              <Col span={8} className="display-flex">
                <div className="application-main__dataLabel">Код домофона:</div>
                <div className="application-main__dataValue">{application?.address?.intercomCode}</div>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={16} className="display-flex">
                <div className="application-main__dataLabel">Подъезд:</div>
                <div className="application-main__dataValue">{application?.address?.entrance}</div>
              </Col>
              <Col span={8} className="display-flex">
                <div className="application-main__dataLabel">Этаж:</div>
                <div className="application-main__dataValue">{application?.address?.floor}</div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const renderContactDataAdditional = () => {
    return (
      <>
        {application?.additionalAddress?.map((address, index) => {
          const subwayStationName = getDictionaryItemById(subwayStation, address?.subwayStationId)?.name;
          const unstructuredAddress = address?.unstructuredAddress || '';

          return (
            <>
              <h3 className="mb5 text-bold">
                <Row>Адрес{` ${nameAddress[index]}`}</Row>
              </h3>

              <Divider className="thin-divider mt0 mb10" />

              <Row gutter={20}>
                <Col span={16} className="display-flex">
                  <div className="application-main__dataLabel">Адрес:</div>
                  <div className="application-main__dataValue" title={unstructuredAddress}>
                    {unstructuredAddress}
                  </div>
                </Col>
                <Col span={8} className="display-flex">
                  <div className="application-main__dataLabel">Метро:</div>
                  <div className="application-main__dataValue">{subwayStationName}</div>
                </Col>
              </Row>
              {(meIsChosenExecutor || hasAccess(['APPLICATIONS.READ.FULLACCESS'])) && (
                <>
                  <Row gutter={20}>
                    <Col span={16} className="display-flex">
                      <div className="application-main__dataLabel">Квартира:</div>
                      <div className="application-main__dataValue">{address?.apartment}</div>
                    </Col>
                    <Col span={8} className="display-flex">
                      <div className="application-main__dataLabel">Код домофона:</div>
                      <div className="application-main__dataValue">{address?.intercomCode}</div>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span={16} className="display-flex">
                      <div className="application-main__dataLabel">Подъезд:</div>
                      <div className="application-main__dataValue">{address?.entrance}</div>
                    </Col>
                    <Col span={8} className="display-flex">
                      <div className="application-main__dataLabel">Этаж:</div>
                      <div className="application-main__dataValue">{address?.floor}</div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          );
        })}
      </>
    );
  };

  const renderContactDataEditing = () => (
    <>
      <h3 className="mb5 text-bold">
        <Row>Адрес А</Row>
      </h3>

      <Divider className="thin-divider mt0 mb10" />

      <Row gutter={20}>
        <Col span={16}>
          <SuggestInput disableOnBlur={true} name="unstructuredAddress" required />
        </Col>
        <Col span={8} className="display-flex">
          <Form.Item name="subwayStationId" label="Метро" style={{ width: '100%' }}>
            <Select placeholder="Выберите из списка" showSearch filterOption={selectFilterOption} allowClear>
              {subwayStation
                .filter((el) => !el.deleted)
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row wrap={false} gutter={30}>
        <Col flex="25%">
          <Form.Item name="apartment" label="Квартира">
            <Input placeholder="Номер" />
          </Form.Item>
        </Col>
        <Col flex="25%">
          <Form.Item name="intercomCode" label="Код домофона">
            <Input placeholder="Код" maxLength={15} />
          </Form.Item>
        </Col>
        <Col flex="25%">
          <Form.Item name="entrance" label="Подъезд">
            <Input placeholder="Подъезд" />
          </Form.Item>
        </Col>
        <Col flex="25%">
          <Form.Item name="floor" label="Этаж">
            <Input placeholder="Этаж" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const renderContactDataEditingAdditional = () => (
    <Form.List name="additionalAddress">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <h3 className="mb5 text-bold">
                  <Row>
                    Адрес{` ${nameAddress[index]}`}
                    <DeleteIcon
                      className="mt1"
                      title="Удалить Адрес"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Row>
                </h3>
                <Divider className="thin-divider mt0 mb10" />
                <Row gutter={20}>
                  <Col flex="65%">
                    <SuggestInput disableOnBlur={true} index={index} name="unstructuredAddress" required />
                  </Col>
                  <Col flex="auto">
                    <Form.Item name={[index, 'subwayStationId']} label="Метро">
                      <Select placeholder="Выберите из списка" showSearch filterOption={selectFilterOption} allowClear>
                        {subwayStation
                          .filter((el) => !el.deleted)
                          .map(({ id, name }) => (
                            <Select.Option key={id} value={id}>
                              {name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="25%">
                    <Form.Item name={[index, 'apartment']} label="Квартира">
                      <Input placeholder="Номер" />
                    </Form.Item>
                  </Col>
                  <Col flex="25%">
                    <Form.Item name={[index, 'intercomCode']} label="Код домофона">
                      <Input placeholder="Код" maxLength={15} />
                    </Form.Item>
                  </Col>
                  <Col flex="25%">
                    <Form.Item name={[index, 'entrance']} label="Подъезд">
                      <Input placeholder="Подъезд" />
                    </Form.Item>
                  </Col>
                  <Col flex="25%">
                    <Form.Item name={[index, 'floor']} label="Этаж">
                      <Input placeholder="Этаж" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
            {fields.length < 1 && (
              <Row>
                <Button
                  type="primary"
                  className="create-application__addAddressBtn mb10"
                  icon={<PlusIcon />}
                  onClick={() => {
                    FireBaseService.createLogEventFireBase('Событие. Добавить адрес Б');
                    add();
                  }}
                >
                  Добавить адрес
                </Button>
              </Row>
            )}
          </div>
        );
      }}
    </Form.List>
  );

  return (
    <>
      <h3 className="text-bold mb3">Адрес и контактные данные</h3>
      <Divider className="thin-divider mt0 mb7" />
      <Row gutter={20}>
        <Col span={16}>
          <div style={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}>
            {!editModeMain && renderContactData()}
            {!editModeMain && renderContactDataAdditional()}
            {!editModeMain && (
              <Row className="mt5">
                <PhonesTableDisplay phones={application?.phones} />
              </Row>
            )}
            {editModeMain && renderContactDataEditing()}
            {editModeMain && renderContactDataEditingAdditional()}
            {editModeMain && (
              <Row>
                <Col flex="auto">
                  <PhonesTable style={{ border: '1px solid #7590a3' }} isValidMainPhone={false} />
                </Col>
              </Row>
            )}
          </div>
        </Col>
        <Col span={8}>{renderMap()}</Col>
      </Row>
    </>
  );
};
